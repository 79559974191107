import { graphql, useStaticQuery, Link } from "gatsby";
import { FaPhone, FaMobile } from "react-icons/fa";
import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../images/logo.png";

const LogoImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40px);
  width: 180px;
  z-index: 100;
`;

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-white">
      <div className="flex flex-wrap items-center justify-end max-w-6xl mx-auto p-12 sm:p-6 relative">
        <LogoImg src={Logo} alt="Logo" />
        <div className="">
          <h4 className="hidden sm:block text-gray-800">
            <FaMobile
              className="text-blue-400"
              style={{ display: "inline", marginRight: "10px" }}
            />{" "}
            07986 445 145
          </h4>
          <h4 className="hidden sm:block text-gray-800">
            <FaPhone
              className="text-blue-400"
              style={{ display: "inline", marginRight: "10px" }}
            />{" "}
            01992 523296
          </h4>
        </div>

        {/* <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-dark"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button> */}

        {/* <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Home`
            },
            {
              route: `/about`,
              title: `About`
            },
            {
              route: `/contact`,
              title: `Contact`
            }
          ].map(link => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-dark"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav> */}
      </div>
    </header>
  );
}

export default Header;
