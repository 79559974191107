import PropTypes from "prop-types";
import Helmet from "react-helmet";
import React from "react";
import { FaPhone } from "react-icons/fa";
import "../css/fonts.scss";
import "../css/style.css";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lato|PT+Serif&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header />

      {children}

      <footer className="bg-gray-700">
        <nav className="flex flex-col items-center flex-wrap justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
          <h3 className="text-white">
            <FaPhone style={{ display: "inline", marginRight: "10px" }} /> 07986
            445 145
          </h3>
          <p className="text-white">M&J Cleaning 1986 - 2020.</p>
          <p className="text-white">
            Another cracking site by {` `}
            <a
              className="font-bold no-underline text-white"
              href="https://kozocreative.com"
            >
              Kozo Creative
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
